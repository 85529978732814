export default {
    "message": {
        "hello": "مرحبا بالعالم"
    },
    "SaidAbout": "قيل عن لوجيكسا",
    "More": "المزيد",
    "Loading...": "جارى التحميل...",
    "whPhone": '0550 800 510',
    sentSuccessfully: "تم الارسال بنجاح",
     anErrorHaveOccurred:
        " عذرا لقد حدث خطاء بالنظام. برجاء اعادة المحاوله او التواصل معنا على بريد support@logexa.com",

    weWillContactYouSoon:"تم استلام رسالتك بنجاح وسنتواصل معك قريبا",
    subscribedSuccessfully: "تم الاشتراك بنجاح",
    yourName:"اسمك",
    "cograturationForSP":"🎉 تهانينا لقبولك كمزود خدمة في لوجيكسا 🎉",
    seo: {
        title: "أفضل مستودع تخزين للايجار جدة – أفضل مستودع للايجار الشهري بالرياض",
        description: "إذا كنت تبحث عن مستودع صغير للايجار الشهري فقد وصلت! مع لوجيكسا لحلول تخزين المستودعات أصبح بإمكانك تخزين منتجاتك في الرياض وجدة بأمان مع إمكانية إدارة مخزنك عبر منصتنا ",
        keywords: ", خدمات لوجستية, الخدمات اللوجستية في السعودية, شركة لوجستية, خدمات اللوجستية, شركة للخدمات اللوجستية, ادارة المخزون, إدارة المستودعات, طرق التخزين في المستودعات, نظام التخزين في المستودعات, إدارة الخدمات اللوجستية, الشركات اللوجستية, القطاع اللوجستي, اللوجستية السعودية, شروط التخزين في المستودعات, قطاع الخدمات اللوجستية, الخدمات اللوجستية السعودية, مستودعات, تخزين, مستودع صغير للايجار, ايجار مستودع, تأجير مساحات تخزين, مستودعات للايجار, مستودعات تخزين, مشروع مستودعات تخزين, تخزين مستودع, الخدمات اللوجستية,  #لوجيكسا #تخزين #تخزين_ذكي #بضائع #تجارة_إلكترونية #السعودية #الرياض #جدة #الدمام ",

        main_page: {
            title: "أفضل مستودع تخزين للايجار جدة – أفضل مستودع للايجار الشهري بالرياض",
            description: "إذا كنت تبحث عن مستودع صغير للايجار الشهري فقد وصلت! مع لوجيكسا لحلول تخزين المستودعات أصبح بإمكانك تخزين منتجاتك في الرياض وجدة بأمان مع إمكانية إدارة مخزنك عبر منصتنا ",
            keywords: "",
        },
        about_us: {
            title: "من نحن لوجيكسا",
            description: "لوجيكسا هي أول منصة إلكترونية سعودية تسمح لك بتخزين بضائعك من خلال توفير خيارات تخرين مختلفة في عدة مواقع مع إمكانية إدارة المستودع عبر خدمة المستودع السحابي",
            keywords: "",
        },
        contact_us: {
            title: "تواصل معنا",
            description: "بإمكانك التواصل معنا بكل سهولة عبر منصتنا الإلكترونية، أو بالاتصال على الرقم الآتي: +966 544 311 384  أو بارسال بريد إلكتروني إلى عنواننا: info@logexa.com",
            keywords: "",
        },
        transportation_page: {
            title: "خدمات الشحن",
            description: "تجربة شحن سلسة وموثوقة مع خدمات الشحن الأفضل في المملكة العربية السعودية. نقدم حلولاً شاملة لنقل بضائعك بأمان وفعالية. اكتشف خياراتنا الآن!",
            keywords: "",
        },
        pallet_storage_page: {
            title: "التخزين بالطبلية ",
            description: "اكتشف حلول التخزين بالطبلية الفعالة والموفرة للمساحة مع لوجيكسا.  نوفر مساحات تخزينية مختلفة وحاويات متينة تناسب جميع الاحتياجات",
            keywords: "",
        },
        sqm_storage_page: {
            title: "منصة لإدارة مخزونك ",
            description: "يمكنك إدارة مخزونك عن طريق منصتنا و طلب خدمات عديدة مثل: شحن منتجاتك الى المستودع، سحب بضاعتك و غيرها.",
            keywords: "",
        },
        howitworks_page: {
            title: "كيف نعمل",
            description: "مع منصة لوجيكسا للحلول اللوجستية أصبح بإمكانك حجز مساحتك التخزينية بثلاث خطوات بسيطة. مع إمكانية إدارة المخزون عبر خدمة المستودع السحابي",
            keywords: "",
        },
        blog_page: {
            title: "مدونة لوجيكسا",
            description: "استكشف مدونة لوجيكسا للحصول على معلومات قيمة حول أحدث التطورات والاستراتيجيات في المجال اللوجستي. نصائح وأفكار مختلفة لإدارة المخزون بفعالية اقرأ المزيد الآن",
            keywords: "",
        },
        news_page: {
            title: "اخبار لوجيكسا",
            description: "ابقَ على اطّلاعٍ بأحدث أنشطة لوجكسا",
            keywords: "",
        },

    },
    super_nav: {
        account: "حسابي",
        login_signup: "تسجيل الدخول",
        dashboard: "لوحة التحكم",
        my_profile: "حسابي",
        quotations: "عودة الى عرض السعر",
        logout: "تسجيل الخروج",
    },
    footerEmailPlaceholder:"عنوان بريدك الإلكتروني",
    careers: {
        hero: {
            title: "كن جزءًا من بناء شيء يحبه الناس.",
            see_openings_cta: "نظر الى وظائف شاغرة",
        },
        why_to_join: {
            title: "لماذا تنضم إلينا؟",
            description: "نحن لسنا سوى عائلة.",
            reason1: {
                heading: "اوقات عمل مرن",
                content: "",
            },
            reason2: {
                heading: "التأمينات الاجتماعية",
                content: "",
            },
            reason3: {
                heading: "تأمين طبي",
                content: "",
            },
        },
        job_openings: {
            title: "فرص العمل",
            description: "نحن نتوسع ، كن جزءًا من قصة نجاحنا القادمة.",
            cities: {
                jeddah: "جدة",
                riyadh: "الرياض",
            },
            departments: {
                sales: "المبيعات",
                finance: "المالية",
                quality_assurance: "ضمان الجودة",
                data: "بيانات",
                marketing: "التسويق",
            },
        },
        career_list: {
            job_location: {
                on_site: "في الموقع",
                remote: "عن بعد",
            },
        },
    },
    contact_us: {
        hero: {
            heading: "خدمة العملاء",
        },
        form: {
            title: "أرسل لنا رسالة",

            name: "الاسم",
            name_placeholder: "اكتب اسمك الكامل هنا",
            email: "عنوان البريد الالكتروني",
            mobile:"رقم الجوال",
            email_placeholder: "اكتب عنوان بريدك الكتروني هنا",
            message: "رسالتك",
            message_placeholder: "ضع رسالتك هنا",
        },
    },
    footer_v2: {
        general: "معلومات عامه",
        for_you: "لك",
        other: "أخرى",
        home: "الصفحة الرئيسية",
        about_us: "من نحن",
        how_it_works: "كيف نعمل",
        blog: "المدونة",
        pallet_storage: "التخزين بالطبليه",
        transportation: "النقل",
        sqm_storage: "التخزين بالمتر المربع",
        service_provider: "مقدم الخدمة",
        contact_us: "تواصل معنا",
        privacy_policy: "سياسة الخصوصية",
        terms_of_use: "شروط الاستخدام",
        careers: "الوظائف",
        faq: "أسئلة وأجوبة",
        footer_description: "اشترك فى نشرتنا الاخباريه وستحصل على خصم 10% على اول طلب لك",
    },
    "Phone": '053 734 4487',
    "English": "English",
    "ShowMore": "عرض المزيد",
    "nav": {
        "home": "الصفحة الرئيسية",
        "about": "حول",
        "contact": "اتصل",
        "HowWeWork": "كيف نعمل",
        "OurServices": "خدماتنا",
        "AboutUs": "من نحن",
        "OurResources": "مواردنا",
        "Login": "تسجيل الدخول"
    },
    "footer": {
        "General": "عام",
        "ForYou": "من اجلك",
        "Others": "اخرى",
        "Home": "الرئيسية",
        "HowWeWork": "كيف نعمل",
        "AboutLogexa": "عن لوجيكسا",
        "Blog": "مدونة",
        "StoreByPallet": "تخزين بالطبلية",
        "StoreByCarton": "تخزين بالكرتون",
        "Delivery": "التوصيل",
        "ServiceProvider": "مقدم الخدمة",
        "ContactUs": "اتصل بنا",
        "PrivicyPolicy": "سياسة الخصوصية",
        "TermsAndConditions": "الشروط والأحكام",
        "Careers": "وظائف",
        "FAQ": "الأسئلة الشائعة",
        "disc_header": "احصل على خصم 10%",
        "disc_desc": "اشترك في النشرة الإخبارية لدينا واحصل على خصم 10% على عرض أسعارك الأول.",
        "disc_desc2": "احصل على تنبيهات بشأن العروض والمواقع الجديدة والمزيد!"
    },
    solution: {
        heading: 'مستودعات صغيرة للايجار الشهري في جدة والرياض و الدمام تناسب متطلباتك',
        desc: "مع لوجيكسا أصبح بإمكانك حفظ منتجاتك في مستودعات تخزين متعددة الأحجام في كل من الرياض وجدة والدمام وباقي المدن في المملكة. وليس ذلك فحسب، من خلال منصتنا يمكنك إدارة مخزونك وتنظيم الشحنات من وإلى المستودع بما يتناسب مع متطلبات عملك. ",
        Question1: "مستودعات تخزين للايجار في جدة والرياض والدمام تناسب جميع احتياجاتك ",
        answer1: "هدفنا في لوجيكسا توفير مستودعات للايجار بالرياض وجدة والدمام وغيرها من المدن الأخرى بعقود شهرية لتسهيل العمليات اللوجستيه للشركات الصغيرة و المتوسطه ومساعدتها على النمو.  ",
        Question1_2: "إمكانية إدارة المخزون من خلال منصتنا ",
        answer1_2: "أصبح الآن بإمكانك التوسع دون القلق بشأن حجم المخزون. حرصاً منا على مصلحة العميل قمنا بتوفير خدمات تخزين مرنة وبعقود ايجار شهرية دون الحاجة إلى الالتزام بعقود طويلة الأجل أو مساحات تخزينية كبيرة، كل ما عليك القيام به هو اعلامنا بحجم المنتجات المراد تخزينها لتوفير مخازن ملائمة لها دون الحاجة إلى الالتزام بعدد معين من الطبالي أو الأمتار المربعة. ",

        Question2: "حلول تخزين ملائمة لجميع منتجاتك",
        answer2: "يمكن لدرجات الحرارة المرتفعة في السعودية وتحديداً جدة والرياض و الدمام أن تسبب تلف منتجاتك، وحرصاً منا على سلامتها نعمل على توفير مخازن ومستودعات صغيرة وكبيرة للايجار الشهري بدرجات حرارة مختلفة تلائم المواد المراد تخزينها. ",
    },
    "Hero": {
        "smallHead": "دائما توجد مساحه لك",
        "Title1": "الحل الافضل ",
        "ForStoring": "للتخزين",
        "Title2": "داخل المملكة العربية السعودية",
        "askQoutation": "طلب عرض سعر",
        "CompleteTheQuotationRequest": "أكمل طلب عرض سعر",
        "SelectTemperature": "اختر درجة الحراره",
        "SelectProductType": "اختر نوع المنتج",
        "SelectStorageType": "أختر نوع التخزين",
        "StoredSku": "منتج تم تخزينه",
        "ClientTrustsUs": "عميل يثق بنا",
        "BrandsTrustUs": "العديد من العلامات التجاريه التي تثق في ",
        "Logexa": "لوجيكسا",
        "WhBenefit": "حاب تستفيد من مستودعك؟",
        "JoinUsAsSP": "إنضم الينا كمزود خدمه",
        "YourWarehouseReady": "مستودعك جاهز ومساحتك تنتظرك!",
        "YourWarehouseReady_desc": "من خلال المنصه الاولى و الافضل في السعوديه لتوفير الحلول وتسهيل رحلتك اللوجستيه اصبح الان بامكانك ادارة مستودعك وتخزين منتجاتك ونقلها من والى المستودع دون الحاجه الى التحدث مع الموظفين",
        "WhBenefit_desc": "لوجيكسا دائما تهتم لنجاحكم فلذلك نهتم بالعمل معكم! نساعد المستودعات في استخدام مساحتهم الغير مستخدمة لتحويل التكلفة الى ارباح دون عناء التعامل مع عملاء مختلفين في آن واحد.",
        "whyLogexa_desc": "لوجيكسا هي أول منصة سعودية لتخزين المنتجات حسب الطلب وبعقد ايجار شهري في مخازن متعددة المساحات بالرياض وجدة و الدمام. استعانةً بخدماتنا لا داعي للقلق بشأن مخزونك، أياً كان حجم المخزون. تواصل معنا للاستفادة من خدمات التخزين المرن.",
        "ReadyWarehouse": "مستودع جاهز",
        "AskRFQ": "طلب عرض سعر",
        "desc_1": "تقدر من خلال لوجيكسا تخزن منتجاتك في مستودعات الرياض , جده , الدمام و المدن الاخرى بامان وبعقود شهرية .",
        "desc_2": "ويمكنك ايضا ادارة مخزونك من خلال منصتنا وانشاء الشحنات من والى المستودع"
    },
    "product": {
        "title": "تنوع المنتجات",
        "desc": "في لوجيكسا، نقوم بتوفير مساحات تخزين آمنة ومناسبة لمختلف أنواع المنتجات للحفاظ على جودتها. "
    },
    "contact_us_card": {
        "contact_us": "تواصل مع خبراء لوجكسا",
        "lets_have_a_conversation": "تواصلوا معنا",
        "contact_us_description": "نحن متواجدين لخدمتكم و يسعدنا إجابة جميع أسألتكم.  , اتصل بنا وشاركنا احتياجاتك  لتجدو ما يبهركم خلال تلبية طلبكم"
    },
    "storage_options": {
        "title": "أنواع التخزين",
        "AskQuotation": "طلب عرض سعر",
        "AskQoutation": "خزن معنا",
       // "askQoutation": "طلب عرض سعر",
        "description": "في لوجيكسا نوفر حلول التخزين للمستودعات الأكثر فعالية مع إمكانية التحكم بدرجة الحرارة؛ نوفر لك البيئة الأنسب لمنتجاتك للحفاظ على جودتها. ",
        "option1": {
            "name": "التخزين الجاف",
            "description": "نقدم حلول تخزينية بعقود شهرية مرنة داخل مستودعات خاصة للمنتجات الجافة بعيداً عن الرطوبة وأشعة الشمس المباشرة. "
        },
        "option2": {
            "name": "التخزين المعتدل ",
            "degree": "21-25 درجة مئوية",
            "description": "نوفر مستودعات تخزين للايجار الشهري بالرياض خاصة بالمواد التي تتطلب درجات حرارة معتدلة تتراوح درجة حرارتها بين 21 إلى 25 درجة مئوية. (كدرجة حرارة الغرفة).  "
        },
        "option3": {
            "name": "التخزين المبرد",
            "degree": "5-15 درجة مئوية",
            "description": "حرصاً منا على سلامة مخزونك نعمل على حفظ المنتجات التي تحتاج إلى تبريد في مستودعات تتراوح درجة حرارتها بين 5 إلى 15 درجة مئوية.  "
        },
        "option4": {
            "name": "التخزين المجمد",
            "degree": "-18 درجة مئوية",
            "description": "يتوفر لدينا مستودعات تخزين كبيرة وصغيرة للايجار الشهري في جدة أو الرياض مهيئة للحافظ على جودة منتجاتك المجمدة في درجات حرارة تصل إلى 18 درجة مئوية تحت الصفر. "
        }
    },
    "our_services": {
        "title": "خدماتنا",
        "card": {
            "title1": "التخزين بالطبلية",
            "learnMore": "المزيد عنا",
            "desc": "الطبلية عبارة عن وحدة تخزين للسلع والمواد. تتبع الطبالي المعايير الأوروبية، والقياسات هي = 1.0 م * 1.2 م * 1.6 م. إذا لم تكن منتجاتك على طبالي، فلا مشكلة. نحن نقدم خدمة بناء الطبلية التي تتضمن لوح خشبي، وتنظيم الكراتين على الطبلية، والتغليف الحراري..",
            "title2": "منصة لإدارة مخزونك ",
            "desc2": "يمكنك إدارة مخزونك عن طريق منصتنا و طلب خدمات عديدة مثل: شحن منتجاتك الى المستودع، سحب بضاعتك و غيرها. ",
            "title3": "خدمات النقل",
            "desc3": "إذا كنت بحاجة إلى نقل منتجاتك من وإلى المستودع، فيمكنك القيام بذلك ببساطة عن طريق المنصة و سنقوم بتزويدك بخدمة النقل المناسبة. "
        }
    },
    "faq": {
        "heading": " الاسئلة الشائعة",
        "description": "كل ما تحتاج إلى معرفته عن خدمات لوجكسا وكيفية عملها. هل لا تستطيع العثور على الإجابة؟ يرجى التحدث مع فريقنا للحصول على المساعدة.",

        "faq_list": {
            "question1": {
                "q": "لماذا تختار لوجيكسا؟",
                "a": " 1- تخزين داخل المستودع إبتداءًا من طبلية واحدة.\n 2- من غير عقود طويلة األجل شهر واحد كحد أدنى.\n 3- المرونة.\n 4- منصة إلكترونية إلدارة مخزونك داخل المستودع .\n5- تتوفر جميع أنواع التخزين الجاف، معتدل الحرارة، المبرد والمجمد في مستودعات جده, الرياض و غيرها من المدن"
            },
            "question2": {
                "q": "ما هو التخزين عند الطلب؟",
                "a": "نادرا ما يمتلك أصحاب األعمال الموارد للعثور عىل مساحات عمل مصممة لتلبية احتياجاتهم الفريدة. ولكن عندما يجتمع أصحاب األعمال هؤالء في موقع مركزي واحد(مثل لوجيكسا)، يمكنهم الوصول إىل كل من المساحات والخدمات التي يحتاجون إليها بشكل فردي.واالستفادة من المعرفة المشتركة والشعور باالنتماء للمجتمع. تساعد لوجكسا اصحاب االعمال للعثور علي مستودعات في جده, الرياض وغيرها من المدن االخرى للتخزين المؤقت او لمدة زمنيه طويله وتوفير المساحاتواالحتياجات واالستفاده منها"
            },
            "question3": {
                "q": "كيف يمكنني طلب عرض سعر؟",
                "a": "يمكنك طلب عرض السعر لمنتجك لتخزينه داخل المستودع بسهولة من خلال منصة لوجيكسا"
            },
            "question4": {
                "q": "ما هي خيارات الدفع المتاحة؟",
                "a": "لدينا خيارات دفع متعددة لراحتك التحويل البنكي / مدى / أبل باي."
            },
            "question5": {
                "q": "هل تمتلك لوجيكسا المستودعات؟",
                "a": "ال، ولكن لدينا شبكة كبيرة من مزودي الخدمة ونخدم عمالئنا من خالل مساعدة المستودع من االستفادة من المساحات المتاحه"
            },
            "question6": {
                "q": "أين يقع المكتب الرئيسي للوجيكسا؟",
                "a": "جدة، المملكة العربية السعودية."
            },
            "question7": {
                "q": "ما هي ساعات عمل لوجيكسا؟",
                "a": "من الساعة 8 صباحًا حتى 4 مساءً."
            },
            
            
            // "question10": {
            //     "q": "كيف يمكنني الانضمام لفريق لوجيكسا؟ ",
            //     "a": "يمكنك متابعة صفحة الوظائف وتحميل سيرتك الذاتية. "
            // },
            "question8": {
                "q": "ما هي القطاعات التي تخدمها لوجيكسا ؟",
                "a": "نحن نخدم عدة قطاعات منها تجارة التجزئة والسلع االستهالكية و غذائيةوالمشروبات و قطاع الصناعات."
            },
            "question9": {
                "q": "ما نوع المنتجات التي يمكن للوجيكسا تخزينها ؟",
                "a": "مواد غذائية، مستحضرات تجميل، بضائع خطرة، كيماويات، منتجات شخصية،مالبس، إلخ"
            },
            "question10": {
                "q": "هل يمكنني أن أكون شريك لوجيكسا؟",
                "a": "لوجيكسا منفتحة دائمًا للشراكات الإستراتيجية."
            },
            "question11": {
                "q": "هل هناك أي أكواد ترويجية؟",
                "a": "نعم، يمكنك متابعة صفحات وسائل التواصل االجتماعي الخاصة بنا."
            },
            "question12": {
                "q": "من يتحمل المسؤولية عن المخزون؟ ",
                "a": "نتحمل المسؤولية كاملة عن الخسائر أو األضرار التي تحدث داخل المنشأة. نحن ال نغطي األضرار الناجمة عن القضاء والقدر و / أو أفعال الطبيعة  ويتحمل في ذلك الحالة العميل المسؤولية او التأمين ان وجد"

            }
        },

        "contact_us": {
            "title": "ما زال لديك أسئلة؟",
            "description": "إذا لم تتمكن من العثور على إجابة لسؤالك في الأسئلة الشائعة ، فيمكنك دائمًا الاتصال بنا. سنقوم بالرد عليك قريبا!",
            "cta": "اتصل بنا"
        }
    },
    "landingPage": {
        "BlogSec": {
            "title": "ابقى على اطلاع",
            "desc": "نحن نشارك معك مقالات وأخبار مفيدة حول اللوجستيات والتخزين والتوزيع "
        }
    },
    "common": {
        "logexa": "لوجيكسا",
        "title": "لوجيكسا|أول منصة سعودية للتخزين عند الطلب",
        "rfq": "طلب عرض سعر",
        "requestQuotation": "طلب عرض سعر",
        "slogan": "اعثر على حل التخزين الأكثر مرونة لمنتجاتك في المملكة العربية السعودية",

        "show_article": "عرض المقال",
        "email_address": "عنوان البريد الالكتروني",
        "subscribe": "اشترك",
        "storage": "التخزين",
        "name": "الاسم",
        "locations": "الموقع",
        "departments": "القسم",
        "team": "فريق",
        "expand": "تمديد",
        "collapse": "انقباض",
        "call": "اتصل بنا على",
        "email": "راسلنا على",
        "location": "موقعنا في",
        "our_locations": "مواقعنا",
        "jeddah": "جدة، المملكة العربية السعودية",
        "submit": "إرسال",

        "dry": "التخزين الجاف",
        "ambient": "التخزين المعتدل",
        "chilled": "التخزين المبرد",
        "frozen": "التخزين المجمد",
        "contact_us": "اتصل بنا",
        "copy": "نسخ رابط المقال",
        "login": "تسجيل الدخول",
        "signup": "التسجيل",
        "or": "أو",
        "logout": "تسجيل الخروج",
        "close": "اغلاق",
        "celsius": "درجةالحرارة المئوية",
        "delete": "قم بالحذف",
        "workHours": "من 8 صباح الى 4 مساء",
        "workDays": "السبت الى الخميس ",
        "lets_have_a_conversation": "تواصلوا معنا",
        "store_with_us": "خزن معنا",
        "get_off": "احصل على خصم 10%",
        "your_email_address_here": "بريدك الالكترونى هنا",
        "follow_us": "تابعنا",
        "more_articles": "المزيد من المقالات",
        "share_the_article": "مشاركة المقال",
        "show_more": "عرض المزيد",
        "more_about_us": "المزيد عنا",
        "join": "انضم الينا كمزود خدمه",
        "learnMore": "اعرف المزيد",
        "service_provider": "مقدم الخدمة؟",
        "cancel": "Cancel"
    },
    nav_links_v3: {
        about_us: "من نحن",
        services: "خدماتنا",
        how_it_works: "كيف نعمل",
        resources: "الموارد",
        pallet_storage: "التخزين بالطبليه",
        pallet_storage_description: "نوفر لك مساحات تخزين بالطبليه لتسريع وتحسين كفاءة التعامل وتخزين العناصر الثمينة الخاصة بك",
        transportation: "النقل",
        transportation_description: "سنقوم بتوفير أفضل خدمة نقل للمنتجات الخاصة بك",
        sqm_storage: "التخزين بالمتر المربع",
        sqm_storage_description: "نوفر افضل حلول التخزين لتناسب جميع احتياجات منتجاتك",
        blog: "المدونة",
        blog_description: "اقراء وتعلم كل ما يخص اللوجيستك والتخزين والمزيد! ",
        news: "أخبارنا",
        news_description: "اقراء اخر اخبارنا لمعرفة المزيد عن لوجيكسا ",
    },
    "about_us": {
        "stats": {
            "title1": "العملاء",
            "title2": "المستودعات",
            "title3": "المدن",
            "title4": "المنتجات",
            "desc1": "استخدموا خدماتنا بنجاح",
            "desc2": "انضموا إلى مهمتنا",
            "desc3": "نعمل في أماكن متعددة",
            "desc4": "مخزن في شبكة المستودعات لدينا"
        },
        "why_logexa": {
            "title": "لماذا لوجيكسا؟",
            "description": "نساعد الشركات على النمو من خلال تسهيل حلول التخزين والخدمات اللوجستية",
            "cardtitle1": "نحن مرنون",
            "cardtitle1_desc": "نوفر لعملائنا ما يحتاجونه عندما يحتاجونه. سواء كانوا يبحثون عن التزام بعقد طويل الأجل أو التخزين لمدة شهر، لدينا ما يلبي احتياجاتهم.",
            "cardtitle2": "دائمًا هناك مساحة للمزيد",
            "cardtitle2_desc": "لا توجد قيود على ما يمكنك تخزينه وكيف يمكنك تخزين منتجاتك معنا. سواء كانت أسطولًا من الحاويات أو منصة واحدة، هناك دائمًا مساحة لذلك.",
            "cardtitle3": "منصتنا الرقمية",
            "cardtitle3_desc": "تتيح منصتنا المبتكرة للمستخدمين استكمال عملية الاستشعار الخاصة بهم والدفع وإدارة المخزون الخاص بهم وطلب خدمات ذات قيمة مضافة بأسهل وأكثر أمانًا وبسلاسة، أينما كانوا، سواء في المنزل أو المكتب أو السيارة!",
            "cardtitle4": "شبكة المستودعات لدينا",
            "cardtitle4_desc": "خدماتنا ليست مقتصرة على مواقع محددة، نفخر بتوفير تغطية جغرافية في جميع أنحاء المملكة."
        },
        "our_values": {
            "title": "قيمنا",
            "description": "توفير حرية التخزين حسب الطلب ",
            "Integrity": "النزاهة",
            "Reliability": "الموثوقية",
            "Inclusivity": "الشمولية",
            "Sustainability": "الاستدامة",
            "Sustainability_desc": "نحن هنا في لوجيكسا نقدّر كوكبنا ونحاول أن نلعب دورنا في المحافظة عليه من خلال استخدام المستودعات الحالية في جميع أنحاء المملكة للحدّ من النفايات الصلبة والتلوث الناجم عن بناء مستودعات جديدة.",
            "Inclusivity_desc": "في لوجيكسا، نشجع وندعم جميع الموظفين على التعبير عن أفكارهم وأنفسهم بحرية لتوفير بيئة عمل مريحة ومبدعة للجميع.",
            "Reliability_desc": "نطمح دائمًا للحصول على تفاعلات موثوقة وموثوق بها بين موظفينا ومقدمي الخدمة وعملائنا.",
            "Integrity_desc": "من المهم أن تتصرف الشركة وموظفيها بأخلاقية ومسؤولية لتأسيس علاقات موثوقة داخل الشركة وخارجها."
        },
        "hero": {
            "title": "لوجيكسا هي منصة تخزين حسب الطلب.",
            "desc2": "نحن أول منصة سعودية لتخزين البضائع حسب الطلب، والتي توفر خيارات تخزين متنوعة في عدة مواقع. من خلال خدمات لوجيكسا، يمكن للمستودعات زيادة معدل استخدامها. من جهة أخرى، يمكن للأعمال الاستفادة من تخزين مرن ومنتشر وعدم القلق بشأن مشاكل التوسعة في المخزون.",
            "description": "نحن أول منصة سعودية لتخزين البضائع حسب الطلب التي توفر خيارات تخزين مختلفة في عدة مواقع. من خلال خدمات لوجيكسا، يمكن للمستودعات زيادة معدل استخدامها. وبالمقابل، يمكن للأعمال الاستفادة من تخزين مرن ومنتشر وعدم القلق بشأن مشاكل التوسعة في المخزون. "
        },

        "purpose": {
            "title": "هدفنا",
            "description": "ساعد الأعمال على النمو من خلال تسهيل حلول التخزين واللوجستية."
        },
        "specs": {
            "title": "لماذا لوجيكسا؟",
            "description": "هناك عدة أسباب تجعل الأعمال التجارية تتعاون معنا. نوفر لعملائنا ما يحتاجون إليه عندما يحتاجونه. سواء كانوا يبحثون عن التزام طويل الأمد أو تخزين لمدة شهر، فنحن نستطيع ذلك! لا يوجد قيود على ما تود تخزينه أو كيفية تخزين منتجاتك معنا. سواء كانت أسطولًا من الحاويات أو منصة واحدة، هناك دائمًا مساحة لذلك. تتيح لنا منصتنا المبتكرة للمستخدمين إكمال عملية الاستفسار، والدفع، وإدارة المخزون، وطلب الخدمات الإضافية بأسهل وأكثر أمانًا وبسلاسة، أينما كانوا، سواء في المنزل أو المكتب أو السيارة! خدماتنا ليست محدودة بمواقع معينة، ونحن فخورون بتوفير تغطية جغرافية في جميع أنحاء المملكة.",
            "title1": "نحن مرنون",
            "title2": "هناك دائما مساحة للمذيد",
            "title3": "برنامجنا الرقمي",
            "title4": "شبكة المستودعات لدينا",
            "desc1": "نحن نوفر لعملائنا ما يحتاجونه عندما يحتاجونه. سواء كانوا يبحثون عن التزام طويل الأمد أو تخزين لمدة شهر، نحن نستطيع تلبية ذلك.",
            "desc2": "لا يوجد قيود على ما الذي ترغب في تخزينه أو كيفية تخزين منتجاتك معنا. سواء كانت أسطولًا من الحاويات أو منصة واحدة، هناك دائمًا مساحة متاحة لذلك.",
            "desc3": "منصتنا المبتكرة تتيح للمستخدمين إكمال عملية الاستفسار، والدفع، وإدارة المخزون، وطلب الخدمات الإضافية بأسهل وأكثر أمانًا وبسلاسة، أينما كانوا، سواء في المنزل أو المكتب أو السيارة!",
            "desc4": "خدماتنا ليست محدودة بمواقع معينة، ونحن فخورون بتوفير تغطية جغرافية في جميع أنحاء المملكة.",
            "warehouse_network_spec": {
                "h2": "شبكة المستودعات لدينا",
                "description": "تخزين منتجاتك لم ايكن اسهل من ذلك",
                "step1": "توفرنا خيارات للتخزين على المدى القصير والمدى الطويل.",
                "step2": "وفر الوقت والمال",
                "step3": "ادفع فقط عند الاستخدام"
            },
            "digital_program_spec": {
                "h2": "برنامجنا الرقمي",
                "step1": "منصة تكنولويجه مبتكره",
                "step2": "عروض اسعار فوريه",
                "step3": "تعاملات امنه ومريحه"
            }
        },
        "partners": {
            "title": "بعض شركائنا",
            "description": "نحظى بثقة اكثر من 280 عميل فى جميع انحاء المملكه"
        },
        "team": {
            "title": "فريقنا",
            "description": "وراء كل شركة ناجحة مجموعة استثنائية من أشخاص يقودون الفريق<br>تعرف على الفريق الخارق الذي يدفع شركتنا إلى الأمام",
            "names": {
                "husam_sendi": "حسام سندي",
                "husam_sabano": "حسام سبانو",
                "khalid_nagadi": "خالد نقادي",
               // "Marah_Jarad": "مرح جراد",
                "Nouman_Farooq": "نعمان فاروق",
                "Majed_Alqathmi": "ماجد القثمي",
                "noha_alhasan": "نهى الحسن",
                "Azharuddin_Mohammed": "ازهر الدين محمد",
                //"Waleed_Atiah": "وليد عطية",
                "Saad_Almuqrin": "سعد المقرن",
                "Faisal_Altamehi": "فيصل الطميحي",
                //"Hisham_Alamoudi": "هشام العمودي",
                "Eslam_Dahshan": "اسلام دهشان",
                "Ahmed_Qamar": "احمد قمر",
                //"Taif_Abdulbagi": "طيف عبدالباقي",
                "Duaa_Thaiban": "دعاء ذيبان",
               // "Saleh_Bahashwan": "صالح باحشوان",

                //"Mohammed_Alamoudi": "محمد العمودي",
                "Abdullah_Bokhari": "عبدالله البرخاري",
                //"Raneem_Alghamdi": "رنيم الغامدي",
                //"Duaa_Sulaimani": "دعاء سليماني",
                //"Baraah_Halawani": "براءة حلواني",
                "areej_amin": "اريج امين",
                
                "hasan_mustafa": "حسن مصطفى",
                "abdurahman_suliman": "عبد الرحمن سليمان",
                "ahmed_atif": "احمد عاطف",
                //"hisham_alamoudi": "هشام العمودي",
                "dania_hussien": "دانية حسين",
                //"mohammed_rawas": "محمد رواس",
                "nourhan_tharwat": "نورهان ثروت",
                "alaa_abualhasan": "علاء ابو الحسن",
                //"alaa_mansour": "علاء منصور",
                "abdulkareem_alasami": "عبد الكريم العسمي",
                "afnan_alghamdi": "افنان الغامدي",
                "aalian_ansari": "عليان انصاري",
                "abdullah_alsharif": "عبدالله الشريف",
                "numan_ali": "نعمان علي",
                "muhammed_ibrahim": "محمد ابراهيم",
               // "ahmed_qamaralam": "احمد قمر",
                "faisal_altamehi": "فيصل الطميحي",
                "yusuf_alhabeeb": "يوسف الحبيب",
                "abdullah_bokhari": "عبدالله بخاري",
                "wdad_alharbi": "وداد الحربي",

                //"Jory_Majdi_Waddu": "جوري مجدي ودو",
                //"Mohammed_Rawas": "محمد رواس",
                //"Taha_Osama_Aziz": "طه أسامة عزيز",
                "Khalid_Nagadi": "خالد نجادي",
                "Faris_Almaharasi": "فارس المحارصي",
                "Omar_Mohammed": "عمر محمد",
                "Hassan_Jamal": "حسان جمال"
            },
            "cta_more": "عرض الكل",
            "cta_less": "عرض أقل"
        },
        "contact_us": {
            "title": "هل تريد أن تصبح جزءًا من نجاحاتنا وشريكًا في تقدمنا!",
            "description": "هل تريد أن تصبح جزءًا من نجاحاتنا وشريكًا في تقدمنا!",
            "cta": "اتصل بنا"
        }
    },

    services: {
        product: {
            title: "مستودعات تخزين خاصة لمنتجاتك المتنوعة في جدة والرياض و الدمام ",
            desc: "في لوجيكسا، نقوم بتوفير مساحات تخزين آمنة ومناسبة لمختلف أنواع المنتجات للحفاظ على جودتها. "
        },
        transportation: {
            title: 'النقل',
            description: "إذا كنت بحاجة الى نقل منتجاتك من والى المستودع، فيمكنك ببساطة طلب ذلك عبر المنصة وسنقوم بتوفير خدمة النقل المناسبة لك. "
        },
        palletStorage: {
            title: "تخزين بالطبليه",
            description: "في لوجيكسا، نعامل منتجاتك بأقصى درجات العناية. نوفر التخزين بالطبلية لتسريع وتحسين كفاءة التعامل وتخزين منتجاتك الثمينة. "
        },
        sqm: {
            title: "تخزين بالمتر المربع",
            description: "في مساحة التخزين بالمتر المربع، نوفر المساحة الأكثر مناسبة لتلبية احتياجات منتجاتك مهما كانت متطلباتها. "
        },
        descriptiveSection: {
            title1: "فعال من حيث التكلفة",
            desc1: "توفير خدمات النقل يمكن أن يكون حلاً فعالًا من حيث التكلفة لشركات اللوجستيات، حيث لا يحتاجون إلى استثمار في سياراتهم الخاصة والسائقين. يمكن أيضًا أن يساعد تفويض النقل في تقليل التكاليف العامة مثل الصيانة والوقود والتأمين."
        },
        careSection: {
            mainTitle: "نحن نهتم بكل التفاصيل، ولهذا هناك دائمًا مجال للمزيد.",
            maindesc: "لدينا منشآت عالية الجودة",
            title1: "موظفين مؤهلين بشكل عالي",
            desc1: "نعمل مع موظفين مدربين بشكل عالٍ لزيادة السلامة وتحسين الكفاءة. يتبع الموظفون البروتوكولات والإرشادات داخل الشركة وخارجها لضمان أعلى جودة للخدمة.",
            title2: "ضمان السلامة",
            desc2: "نجعل سلامة فريق العمل ومنتجاتك أولويتنا الأولى. لذا، نبذل قصارى جهدنا لضمان استيفاء جميع إرشادات وبروتوكولات السلامة بشكل مستمر.",
            title3: "مستوى عالٍ من الأمان",
            desc3: "تحتوي جميع المستودعات على كاميرات مراقبة وموظفين أمن يعملون بجد للحفاظ على سلامة سلعك وضمان أنها في حالة جيدة.",
            title4: "قرب من السوق",
            desc4: "شبكتنا من المواقع متصلة تصليحًا ويسهل الوصول إليها. نقدم خدمات النقل المناسبة لبضائع عملائنا إذا لزم الأمر.",
            title5: "مساحة تخزين",
            desc5: "نستخدم آلات مختلفة للتعامل مع جميع أنواع المنتجات. لدينا الجهاز المناسب للتعامل مع الأحمال الكبيرة أو الصغيرة.",
            title6: "تصميم مناسب",
            desc6: "نتعاون مع المستودعات المصممة لتخزين 4 درجات حرارة مختلفة من المنتجات وتستطيع تشغيل الأغراض الكبيرة/الثقيلة في البيئة المطلوبة.",
            title7: "خدمة العملاء",
            desc7: "فريق خدمة العملاء لدينا متاح دائمًا وسعيد للغاية بتلقي مكالمتك ومساعدتك. لذا، لا تتردد في التواصل مع فريقنا الرائع في أي وقت.",
            title8: "أجهزة ميكانيكية",
            desc8: "نستخدم آلات مختلفة للتعامل مع جميع أنواع المنتجات. لدينا الجهاز المناسب للتعامل مع الأحمال الكبيرة أو الصغيرة."
        }
    },
    news: {
        title: "اكتشف أخبار لوجيكسا",
        description: "ابقَ على اطّلاعٍ بأحدث أنشطة لوجكسا"
    },
    serviesInner: {
        transportation: {
            title1: "فعال من حيث التكلفة",
            desc1: "نظرًا لعدم الحاجة لاستثمار شركات اللوجستيات في مركباتها الخاصة والسائقين، قد تكون خدمات النقل العبارة عن خيار أكثر توفرًا. بالإضافة إلى ذلك، يمكن أن يساعد تفويض النقل في تقليل التكاليف المتعلقة بالصيانة والوقود والتأمين.",
            title2: "توفير الوقت",
            desc2: "من خلال تقديم خدمات النقل، يمكن للمؤسسات اللوجستية توفير وقتها من الحاجة إلى تخطيط وإدارة الخدمات اللوجستية الخاصة بها. وبالتالي، ستتمكن من التركيز على الأنشطة التجارية الرئيسية، مما يزيد من الإنتاجية.",
            title3: "قابلية التوسع",
            desc3: "يمكن أن تسهل خدمات النقل توسيع عمليات الشركات اللوجستية. يمكن للشركات تغيير كمية الخدمات النقل التي يحتاجون إليها بسرعة وفقًا لاحتياجات أعمالهم، مثل إضافة أو إزالة السيارات أو السائقين.",
            title4: "تحسين خدمة العملاء",
            desc4: "يمكن أن تعزز خدمات النقل تحسين خدمة العملاء حيث يمكن للشركات اللوجستية توفير تتبع الشحنات في الوقت الحقيقي لعملائها، وتقديم خيارات تسليم مرنة أكثر، وإطار زمني لتوصيل أسرع."
        }
    },
    contactUsPage: {
        title: "على مدار 24 ساعه",
        titleLine2: "خدمة العملاء",
        email: "info@logexa.com",
        location: "جده المملكة العربيه السعوديه",
    },
    serviceProvider: {
        hero: {
            heading: "انضم إلى عائلة لوجيكسا",
            description: "كن شريكًا لوجيكسا واحصل على دخل إضافي.",
        },
        ourMessage: {
            title: "رسالتنا",
            heading: "حاب تستفيد من مستودعك؟",
            desc: "لوجكسا دائما تهتم لنجاحكم فلذلك نهتم بالعمل معكم! نساعد المستودعات في استخدام سعتها غير المستخدمه لزيادة الارباح دون عناء التعامل مع عملاء مختلفين في ان واحد ",
        },
        step1: "املأ نموذجنا وقدمه إلى المنصة.",
        step2: "سنشاركك اتفاقية الخدمة والعقد لتوقيعها.",
        step3: "سنجري تدقيقًا للتأكد من أن مستودعك يستوفي متطلباتنا.",
        benefit: {
            maindesc: "فوائد التعاون معنا",
            mainTitle: "لماذا تنضم إلينا كمقدم خدمة؟",
            1: "كسب المزيد من المال بدون فريق مبيعات أو تسويق.",
            2: "لن تحتاج فرق المبيعات أو التسويق لديك لإنشاء إيرادك حيث ستقوم لوجيكسا بذلك نيابةً عنك عن طريق ملء مستودعك.",
            3: "نظرًا لأنك ستعمل مباشرة مع لوجيكسا، سيتم تبسيط اللوجستيات الخاصة بك مثل الفوترة وتتبع الشحنات والفواتير من قبلنا.",
            4: "نخدم قاعدة متنوعة من الشركات والعملاء في جميع أنحاء المملكة؛ وبدون الحاجة إلى إدارتهم بشكل فردي، يستمتع شركاؤنا بالتعامل معهم تجاريًا.",
            5: "نمو بدون أي صداع",
            6: "اكتساب عملاء جدد بدون عمل إضافي"
        },
        contactUs: {
            title: "حان الوقت للبدء",
            desc: "هل ترغب في أن تصبح جزءًا من نجاحاتنا وشريكًا في تقدمنا!"
        }
    },
    how_it_works: {
        hero: {
            heading: "التخزين والخدمات اللوجستية بسيطة",
            description:
                "وفر لك منصتنا الرقمية المرونة التي تحتاجها للقيام بجميع أنشطة التخزين الخاصة بك بخطوات بسيطة.",
        },
        process: {
            title: "لذلك هناك دائمًا مساحة للمزيد",
            description: "عملية التخزين",
            process1: {
                title: "سلس",
                description:
                    "اتصال فوري بمقدمي خدمات المستودعات مما يتيح الرؤية والتحكم لتلبية توقعات عملائك.",
            },
            process2: {
                title: "ثابت",
                description:
                    "نوفر لك الوصول الفوري إلى الحلول والأدوات اللوجستية.",
            },
            process3: {
                title: "منظم",
                description:
                    "نظامنا للمستودعات ودعم العملاء المخلص يوفر لك تدفق تشغيلي مخصص.",
            },
        },
        steps: {
            title: "كيف يعمل الأمر؟",
            description: "تخزين منتجاتك لم يكن أسهل من ذلك.",
            step1: {
                title: "احجز مساحة",
                description:
                    "بعد التسجيل على منصتنا، قم بملء تفاصيل بسيطة مثل نوع المنتج الخاص بك، وتاريخ التخزين، ودرجة الحرارة المناسبة لإكمال عملية الاقتباس وحجز مساحة داخل مستودعاتنا.",
            },
            step2: {
                title: "تعريف منتجاتك",
                description:
                    "انتقل إلى رمز SKU المنتج لإضافة منتجاتك. في هذه المرحلة، يجب عليك إدخال البيانات المتعلقة بالمنتجات التي ستخزنها معنا، إما يدويًا أو عن طريق تحميل الملفات المطلوبة.",
            },
            step3: {
                title: "شحن واستلام منتجاتك",
                description:
                    "تبدأ رحلة منتجاتك إلى مستودعاتنا لتخزينها بأمان هناك حسب المدة التي تحتاجها.",
            },
            step4: {
                title: "استلم منتجاتك",
                description:
                    "استلم المنتجات التي تحتاجها، في أي وقت تريده، من خلال بضع نقرات فقط على منصتنا الرقمية المبتكرة لرحلة سهلة وخالية من المتاعب في العودة إليك.",
            },
        },
        storage_options: {
            title: "أنواع التخزين",
            description: 'في لوجيكسا نوفر حلول التخزين للمستودعات الأكثر فعالية مع إمكانية التحكم بدرجة الحرارة؛ نوفر لك البيئة الأنسب لمنتجاتك للحفاظ على جودتها. ',
            option1: {
                name: "التخزين الجاف",
                description: "نقدم حلول تخزينية بعقود شهرية مرنة داخل مستودعات خاصة للمنتجات الجافة بعيداً عن الرطوبة وأشعة الشمس المباشرة. ",
            },
            option2: {
                name: "التخزين المعتدل ",
                degree: "21-25 درجة مئوية",
                description: "نوفر مستودعات تخزين للايجار الشهري بالرياض خاصة بالمواد التي تتطلب درجات حرارة معتدلة تتراوح درجة حرارتها بين 21 إلى 25 درجة مئوية. (كدرجة حرارة الغرفة).  ",
            },
            option3: {
                name: "التخزين المبرد",
                degree: "5-15 درجة مئوية",
                description: "حرصاً منا على سلامة مخزونك نعمل على حفظ المنتجات التي تحتاج إلى تبريد في مستودعات تتراوح درجة حرارتها بين 5 إلى 15 درجة مئوية.  ",

            },
            option4: {
                name: "التخزين المجمد",
                degree: "-18 درجة مئوية",
                description: "يتوفر لدينا مستودعات تخزين كبيرة وصغيرة للايجار الشهري في جدة أو الرياض مهيئة للحافظ على جودة منتجاتك المجمدة في درجات حرارة تصل إلى 18 درجة مئوية تحت الصفر. ",
            },
        },
        contact_us: {
            description:
                "هل ترغب في أن تصبح جزءًا من نجاحنا وشريكًا في تقدمنا؟",
            cta: "تواصل معنا",
        },
    },
    blog: {
        title: "اكتشف بعض المقالات الرائعة لدينا",
        description: "اقراء وتعلم المزيد حول التخزين والمستودعات",
        description2:
            "في لوجيكسا، نعمل بشكل مستمر على اكتساب المعرفة ونسعى لإلقاء الضوء على معلومات مفيدة لمجتمعنا لتسهيل النمو ونشر الوعي حول القضايا والمواضيع ذات الصلة.",
        cta: "اعرض المزيد",
        article: {
            related: "مقالات مشابهه",
            share_cta: "شارك هذا المقال",
            more_articles: "المزيد من المقالات",
            share_title: "شارك هذا المقال",
            copied: "تم النسخ",
            by: "بواسطة",
        },
    },  
}
